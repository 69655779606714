// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cloud-services-why-aws-js": () => import("./../src/pages/cloud-services/why-aws.js" /* webpackChunkName: "component---src-pages-cloud-services-why-aws-js" */),
  "component---src-pages-cloud-services-why-cloud-js": () => import("./../src/pages/cloud-services/why-cloud.js" /* webpackChunkName: "component---src-pages-cloud-services-why-cloud-js" */),
  "component---src-pages-cloud-services-why-us-js": () => import("./../src/pages/cloud-services/why-us.js" /* webpackChunkName: "component---src-pages-cloud-services-why-us-js" */),
  "component---src-pages-company-about-us-js": () => import("./../src/pages/company/about-us.js" /* webpackChunkName: "component---src-pages-company-about-us-js" */),
  "component---src-pages-company-careers-js": () => import("./../src/pages/company/careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-company-team-js": () => import("./../src/pages/company/team.js" /* webpackChunkName: "component---src-pages-company-team-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-imprint-js": () => import("./../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-outstaffing-fields-of-expertise-js": () => import("./../src/pages/it-outstaffing/fields-of-expertise.js" /* webpackChunkName: "component---src-pages-it-outstaffing-fields-of-expertise-js" */),
  "component---src-pages-it-outstaffing-industries-js": () => import("./../src/pages/it-outstaffing/industries.js" /* webpackChunkName: "component---src-pages-it-outstaffing-industries-js" */),
  "component---src-pages-it-outstaffing-smart-outstaffing-js": () => import("./../src/pages/it-outstaffing/smart-outstaffing.js" /* webpackChunkName: "component---src-pages-it-outstaffing-smart-outstaffing-js" */),
  "component---src-pages-it-outstaffing-tech-stack-js": () => import("./../src/pages/it-outstaffing/tech-stack.js" /* webpackChunkName: "component---src-pages-it-outstaffing-tech-stack-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

